import userApi from "./path/user";
import teamApi from "./path/teams";
import authApi from "./path/auth";

export { currentServerUrl, getRedirectDomainUrl } from "./config";

const api = {
  ...userApi,
  ...teamApi,
  ...authApi,
};

export default api;
