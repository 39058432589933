import { ApiResponse, ITeamInfo, ITeamInfoObj } from "../interface";
import { Get, Post, Put } from "../server";

type ITeamInfoArrayObj = { teams: ITeamInfo[] };
function convertTeamData(response?: ITeamInfoArrayObj) {
  let teamInfoList = new Array<ITeamInfoObj>();
  if (response?.teams && Array.isArray(response.teams)) {
    teamInfoList = response.teams.map((team: ITeamInfo) => {
      return {
        teamId: team.team_id,
        displayName: team.display_name,
        personal: team.personal,
        iconUrl: team.icon_url,
        role: team.role,
        defaultPolicy: team.default_policy,
        domain: team.domain,
        memberNum: team.total + (team?.total_pendings || 0),
        expireAt: team?.expire_at,
      };
    });
  }
  return teamInfoList;
}
export function getTeamsInfo(): ApiResponse<ITeamInfoObj[]> {
  return new Promise((resolve, reject) => {
    Get<ITeamInfoArrayObj>("/teams/teams")
      .then((response) => {
        const teamInfoList = convertTeamData(response);
        resolve(teamInfoList);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTeamInvitationList(): ApiResponse<ITeamInfoObj[]> {
  return new Promise((resolve, reject) => {
    Get<ITeamInfoArrayObj>("teams/teams-to-join")
      .then((response) => {
        const teamInfoList = convertTeamData(response);
        resolve(teamInfoList);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createWorkspaceByName<T = { team_id: string }>(teamName: string): ApiResponse<T> {
  return Post<T>("/teams/team", {
    display_name: teamName,
  });
}

export function joinTeamWithId<T = { role: string; team_id: string; user_id: string }>(
  teamId: string,
  code: string
): ApiResponse<T> {
  return Put<T>(`teams/team/${teamId}/join`, { code });
}

export function acceptInvitationByTeamId<T>(teamId: string): ApiResponse<T> {
  return Post<T>(`teams/team/${teamId}/join`, {});
}

const userApi = {
  getTeamsInfo,
  createWorkspaceByName,
  joinTeamWithId,
  getTeamInvitationList,
  acceptInvitationByTeamId,
};

export default userApi;
