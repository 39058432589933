import React from "react";

const CloseBtn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M18 6L6.00018 18M6 6L17.9998 18"
      stroke="#222222"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.33522 5L12 10.6649L17.6648 5L19 6.3352L13.3352 12.0001L18.9998 17.6648L17.6646 19L12 13.3353L6.33538 19L5.00017 17.6648L10.6648 12.0001L5 6.33519L6.33522 5Z"
      fill="#222222"
    />
  </svg>
);

export default CloseBtn;
