import constants from "@/constants";
import { ApiResponse, User } from "../interface";
import { Delete, Get, Post } from "../server";

export function ping<T = { status: string; csrf_token: string }>(): ApiResponse<T> {
  return Get<T>("/ping");
}

export function authUserWithToken<
  T = {
    access_token: string;
    session_key: string;
    user: User;
    csrf_token: string;
    has_password: boolean;
  },
>(accessToken: string): ApiResponse<T> {
  return Post<T>("/auth/sessions", {}, { access_token: accessToken });
}

export function checkEmailLoginStatus<T = { validated: boolean; access_token: string }>(
  checkCode: string
): ApiResponse<T> {
  return Post<T>("/users/registration/check", { check_code: checkCode });
}

export function emailApproval<T>(checkCode: string, registerCode: string): ApiResponse<T> {
  return Post<T>("/users/registration/login", {
    check_code: checkCode,
    register_code: registerCode,
  });
}
export function resolveImpersonate<T>(requestId: string, resolveKey: string): ApiResponse<T> {
  const formData = new FormData();
  formData.append("request_id", requestId);
  formData.append("resolve_key", resolveKey);
  return Post<T>("/impersonate/resolve", formData);
}

// logout
export function logout<T>(): ApiResponse<T> {
  return Delete<T>("/auth", {});
}

// password
export function signInWithPassword<
  T = {
    access_token: string;
    session_key: string;
    user: User;
    csrf_token: string;
  },
>(email: string, password: string, captcha_token: string): ApiResponse<T> {
  return Post<T>(
    "/auth/password",
    { email, password: password },
    {},
    { [constants.X_TURNSTILE_TOKEN]: captcha_token }
  );
}

const authApi = {
  ping,
  authUserWithToken,
  resolveImpersonate,
  logout,
  signInWithPassword,
};

export default authApi;
