import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { AuthProvider, useAuth } from "./hooks";
import Loading from "./components/Loading";

import { routes } from "./constants";
import {
  NotFoundPage,
  Register,
  Login,
  SSOConfirm,
  BoardLoginApproval,
  WebLoginApproval,
  RegisterApproval,
  CreateWorkspace,
  CorsLoginSync,
  RegisterByInvitation,
  SelectAdminWorkspace,
  Logout,
  NoWorkspaceLoading,
  ForgotPassword,
  ResetPassword,
  CheckRegisterOTPCode,
  CheckLoginCode,
  JoinAndSelectWorkspace,
  JoinTeamError,
  JoinTeamSuccess,
  SignWithCodeOrPassword,
  SetPassword,
} from "./pages";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path={routes.corsSync} element={<CorsLoginSync />} />
          <Route path={routes.checkToken} element={<CorsLoginSync isCheckToken />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.logout} element={<Logout />} />
          <Route path={routes.ssoCheck} element={<SSOConfirm />} />
          <Route path={routes.loginApproval} element={<WebLoginApproval />} />
          <Route path={routes.tvApproval} element={<BoardLoginApproval />} />
          <Route path={routes.tvRegisterApproval} element={<RegisterApproval isBoard={true} />} />
          <Route path={routes.registerApproval} element={<RegisterApproval />} />
          <Route path={routes.register} element={<Register />} />
          <Route path={routes.checkRegisterOTPCode} element={<CheckRegisterOTPCode />} />
          <Route path={routes.createWorkspace} element={<CreateWorkspace />} />
          <Route path={routes.noWorkspaceLoading} element={<NoWorkspaceLoading />} />
          <Route path={routes.registerByInvitation} element={<RegisterByInvitation />} />
          <Route path={routes.selectAdminWorkspace} element={<SelectAdminWorkspace />} />
          <Route path={routes.selectWorkspace} element={<JoinAndSelectWorkspace />} />
          <Route path={routes.forgotPassword} element={<ForgotPassword />} />
          <Route path={routes.resetPassword} element={<ResetPassword />} />
          <Route path={routes.loginWithCode} element={<SignWithCodeOrPassword />} />
          <Route path={routes.checkLoginCode} element={<CheckLoginCode />} />
          <Route path={routes.setPassword} element={<SetPassword />} />
          <Route path={routes.joinTeamSuccess} element={<JoinTeamSuccess />} />
          <Route path={routes.joinTeamError} element={<JoinTeamError />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function Layout() {
  const auth = useAuth();

  return (
    <div className="container">
      <Outlet />
      {auth.isLoading && <Loading />}
    </div>
  );
}
