import React from "react";
import "./styles.css";

interface TLoadingButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading: boolean;
}

const LoadingButton = ({ className, isLoading, disabled, children, ...props }: TLoadingButton) => (
  <button className={`vibe-loading-btn ${className}`} disabled={disabled || isLoading} {...props}>
    {isLoading ? <span className="spinner"></span> : children}
  </button>
);

export default LoadingButton;
