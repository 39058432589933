import React, { useEffect } from "react";

import CustomLayout from "@/components/CustomLayout";
import VibeTitle from "@/components/VibeTitle";
import Link from "@/components/CustomLink";

import { useAuth } from "@/hooks/authHooks";
import constants, { routes } from "@/constants";
import { getQueryValueByKeys } from "@/utils";
import Log from "@/utils/Log";

const SSOConfirm = () => {
  const auth = useAuth();
  const email = auth?.authState?.email;
  const ssoURL = auth?.authState?.SSOUrl;

  useEffect(() => {
    if (!email) {
      Log.error("email not exist");
    }
  }, [email]);

  const subtitleContent = (
    <>
      Vibe has detected that <em className="em-email">{email}</em> was registered using single
      sign-on (SSO). Please click &quot;Sign in with SSO&quot; to proceed.
    </>
  );

  const handleConfirmClick = () => {
    if (ssoURL) {
      Log.debug("ssoURL: ", ssoURL);
      const signInFrom: SignInApps =
        getQueryValueByKeys(constants.REDIRECT_FROM) || constants.DEFAULT_SIGN_IN_APP;
      sessionStorage.setItem(constants.REDIRECT_FROM, signInFrom);
      sessionStorage.setItem(constants.REDIRECT_TO, auth.redirectUrl);
      window.location.replace(ssoURL);
    } else {
      Log.error("SSO redirect url not exist!");
    }
  };

  return (
    <CustomLayout contentClassName="max-width-526 flex-wrapper">
      <VibeTitle title="Sign in with SSO" subtitle={subtitleContent} />
      <button className="button-primary-360" type="submit" onClick={handleConfirmClick}>
        Sign in with SSO
      </button>
      <Link className="link-text" to={routes.login}>
        Use different email address
      </Link>
    </CustomLayout>
  );
};

export default SSOConfirm;
