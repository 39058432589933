import React, { useState } from "react";

import { SubmitWorkspaceName } from "@/components/CustomForm";
import CustomLayout from "@/components/CustomLayout";
import VibeTitle from "@/components/VibeTitle";
import LoadingMask from "@/components/LoadingMask";
import { useAuth } from "@/hooks";
import Log from "@/utils/Log";

const CreateWorkspace = () => {
  const [teamId, setTeamId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useAuth();

  const prefilledValue = `${auth.authState.username || "Your"}'s workspace`;

  function handleCreateWorkspace(workspaceName: string) {
    setLoading(true);
    auth.createWorkspace(workspaceName).then((teamId) => {
      Log.debug("create workspace success, team id is", teamId);
      setLoading(false);
      setTeamId(teamId);
    });
  }

  function handleSuccessRedirect() {
    auth.redirectToAppWithAuthParams({ teamId });
    Log.debug("redirectToAppWithAuthParams: ", teamId);
  }

  return (
    <CustomLayout>
      {!teamId ? (
        <>
          <LoadingMask isLoading={loading} />
          <VibeTitle title="Create a workspace" subtitle="Name your workspace." />
          <SubmitWorkspaceName
            isLoading={loading}
            prefilledValue={prefilledValue}
            onSubmit={handleCreateWorkspace}
          />
        </>
      ) : (
        <>
          <VibeTitle
            title="Workspace created successfully"
            subtitle="You can now sign in to your Vibe account."
          />
          <button className="button-primary-360" onClick={handleSuccessRedirect}>
            Continue
          </button>
        </>
      )}
    </CustomLayout>
  );
};

export default CreateWorkspace;
