import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import message from "@/components/CustomMessage";
import { INPUT_INVALID_STATUS, TInputInValidStatus } from "@/components/CustomInput";

import api, { getRedirectDomainUrl } from "@/api";
import type { ITeamInfoObj } from "@/api/interface";
import type { AuthContextType, IAuthResponse, IJoinTeamInfo } from "./interface";

import { addRedirectParam, getQueryValueByKeys, redirectParams } from "@/utils";
import Log from "@/utils/Log";
import constants, { routes } from "@/constants";
import { AxiosError } from "axios";

const initialJoinTeamInfo = {
  email: "",
  code: "",
  teamId: "",
  teamName: "",
};

const AuthContext = React.createContext<AuthContextType>(null!);

export const useRedirectNavigation = () => {
  const navigate = useNavigate();
  const queryParams = redirectParams();

  const getRedirectPath = (path: string) => {
    return `${path}?${queryParams.toString()}`;
  };

  const handleNavigate = (path: string) => {
    const redirectPath = getRedirectPath(path);
    navigate(redirectPath);
  };

  return handleNavigate;
};

function getCaptchaToken(): string {
  const { turnstile } = window;

  if (!turnstile) {
    Log.error("Turnstile is not initialized on window.");
    return "";
  }

  try {
    return turnstile.getResponse();
  } catch (error) {
    Log.error("Error while getting the Turnstile response:", error);
    return "";
  }
}

function resetCaptchaToken() {
  const { turnstile } = window;
  if (!turnstile) {
    Log.error("Turnstile is not initialized on window.");
    return;
  }

  try {
    turnstile.reset();
  } catch (error) {
    Log.error("Error while resetting the Turnstile:", error);
  }
}

function isAdminRole(role?: string) {
  return role && (role === "admin" || role === "owner");
}

function isCurrentEmailUnderRegistration(currentEmail: string) {
  const sessionRegisterInfo = sessionStorage.getItem(constants.REGISTRATION_INFO);
  if (!sessionRegisterInfo) {
    return false;
  }
  const registerEmail = JSON.parse(sessionRegisterInfo)?.email;
  return registerEmail === currentEmail;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const signInFrom: SignInApps = useMemo(() => {
    try {
      const signInAPP =
        getQueryValueByKeys(constants.REDIRECT_FROM) ||
        sessionStorage.getItem(constants.REDIRECT_FROM) ||
        constants.DEFAULT_SIGN_IN_APP;
      return signInAPP;
    } catch (e) {
      Log.error("Failed to access sign in App:", e);
    }
  }, [location.search]);
  Log.debug("signInFrom: ", signInFrom);

  const redirectUrl = useMemo(
    () =>
      getQueryValueByKeys(constants.REDIRECT_TO) ||
      sessionStorage.getItem(constants.REDIRECT_TO) ||
      getRedirectDomainUrl(signInFrom),
    [signInFrom]
  );

  const isLoadingPage = window.location.pathname === routes.registerByInvitation;
  const [isLoading, setLoading] = useState(isLoadingPage);
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [invalidTypes, setInvalidTypes] = useState<string>("");
  const [SSOUrl, setSSOUrl] = useState<string>("");
  const [checkCode, setCheckCode] = useState<string>("");
  const [teamInfoList, setTeamInfoList] = useState<ITeamInfoObj[]>([]);
  const [teamInvitationList, setTeamInvitationList] = useState<ITeamInfoObj[]>([]);
  const [joinTeamInfo, setJoinTeamInfo] = useState<IJoinTeamInfo>(initialJoinTeamInfo);

  const navigate = useRedirectNavigation();

  const redirectToAppWithAuthParams = ({
    teamId,
    redirectApp,
    noValidAccessToken,
  }: {
    teamId?: string;
    redirectApp?: SignInApps;
    noValidAccessToken?: boolean;
  }) => {
    setLoading(true);
    const accessToken = noValidAccessToken
      ? "no_valid_token"
      : localStorage.getItem(constants.ACCESS_TOKEN) || "";
    const redirectAppURL = redirectApp ? getRedirectDomainUrl(redirectApp) : redirectUrl;
    const redirectAddress = addRedirectParam(redirectAppURL, teamId, accessToken);
    Log.debug("loginSuccessRedirect redirectAddress: ", redirectAddress);

    sessionStorage.removeItem(constants.REDIRECT_FROM);
    sessionStorage.removeItem(constants.REDIRECT_TO);
    window.location.replace(redirectAddress);
  };

  const registerUserWithPassword = async (email?: string, username?: string, password?: string) => {
    let registerRes = false;
    let emailHasRegistered = false;

    if (!email || !username || !password) {
      Log.debug("missing user's information, retry");
      navigate(routes.register);
      return { registerRes, emailHasRegistered };
    }

    setEmail(email);
    setUsername(username);
    setPassword(password);
    sessionStorage.setItem(constants.HIDE_ERROR_MSG, "register");

    try {
      const checkRes = await api.userLoginStatus(email, getCaptchaToken());
      resetCaptchaToken();

      if (checkRes?.registered) {
        Log.debug("user's email has been registered, redirect to login");
        emailHasRegistered = true;
        return { registerRes, emailHasRegistered };
      }

      if (checkRes?.sso_url) {
        Log.debug("sso_url: ", checkRes.sso_url);
        setSSOUrl(checkRes.sso_url);
        navigate(routes.ssoCheck);
        return { registerRes, emailHasRegistered };
      }

      const res = await api.getRegistrationCheckCode(email, getCaptchaToken());
      resetCaptchaToken();
      Log.debug("get registration check_code res: ", res);

      if (!res?.check_code) {
        Log.error("missing check code");
        navigate(routes.register);
        return { registerRes, emailHasRegistered };
      }

      sessionStorage.setItem(
        constants.REGISTRATION_INFO,
        JSON.stringify({ email, username, password, checkCode: res.check_code })
      );
      sessionStorage.setItem(constants.EMAIL_KEY, email);
      setCheckCode(res.check_code);
      registerRes = true;
      navigate(routes.checkRegisterOTPCode);
    } catch (error) {
      Log.error("get registration check_code error: ", error);
      navigate(routes.register);
    } finally {
      sessionStorage.removeItem(constants.HIDE_ERROR_MSG);
    }

    return { registerRes, emailHasRegistered };
  };

  // auth with access_token and select workspace
  const authUser = (token?: string) =>
    new Promise<boolean>((resolve) => {
      Log.debug("auth User: 00000000000000");
      const accessToken = new URLSearchParams(location.search).get(constants.ACCESS_TOKEN) || token;
      if (!accessToken) {
        setLoading(false);
        Log.debug("accessToken doesn't exist");
        resolve(false);
        return;
      }
      authWithToken(accessToken).then((res) => {
        Log.debug("auth With Token res: ", res);
        if (!res?.csrf_token) {
          Log.debug("auth with token failed");
          resolve(false);
          return;
        }
        resolve(true);
        selectWorkspaceProcess();
      });
    });

  const checkRegistrationCode = async (
    email: string,
    username: string,
    password: string,
    checkCode: string,
    registerCode: string
  ): Promise<TInputInValidStatus> => {
    let checkRes = INPUT_INVALID_STATUS.INITIAL;

    try {
      sessionStorage.setItem(constants.HIDE_ERROR_MSG, "registerByCode");
      const res = await api.registerWithPassword(
        email,
        username,
        password,
        checkCode,
        registerCode,
        getCaptchaToken()
      );
      sessionStorage.removeItem(constants.REGISTRATION_INFO);
      sessionStorage.removeItem(constants.HIDE_ERROR_MSG);
      setCheckCode("");
      Log.debug("user register res: ", res);
      setLoading(true);
      if (res?.access_token) {
        await authUser(res.access_token);
      }
      return Promise.resolve(checkRes);
    } catch (error) {
      Log.error("user register error: ", error);
      checkRes = INPUT_INVALID_STATUS.INVALID;
      if ((error as AxiosError)?.response?.status === 404) {
        Log.debug("Invalid Register Code");
      }
      return Promise.resolve(checkRes);
    } finally {
      resetCaptchaToken();
    }
  };

  // check auth access_token with no redirect process
  const authWithToken = (token: string) =>
    new Promise<IAuthResponse>((resolve) => {
      let authRes: IAuthResponse;
      api
        .authUserWithToken(token)
        .then((res) => {
          if (!res?.csrf_token) {
            Log.debug("csrf_token doesn't exist");
            return;
          }
          if (res?.user) {
            const user = res.user;
            user?.email && setEmail(user.email);
            user?.display_name && setUsername(user.display_name);
          }
          sessionStorage.setItem(constants.X_CSRF_TOKEN, res.csrf_token);
          localStorage.setItem(constants.ACCESS_TOKEN, token);
          authRes = res;
        })
        .catch((err) => {
          localStorage.removeItem(constants.ACCESS_TOKEN);
          Log.error("auth user err: ", err);
        })
        .finally(() => {
          resolve(authRes);
          sessionStorage.removeItem(constants.HIDE_ERROR_MSG);
        });
    });

  const joinWorkspacesByTeamIds = (teamIdList: string[]) =>
    new Promise<boolean>((resolve) => {
      Log.debug("teamIdList: ", teamIdList);
      const promises = teamIdList.map((teamId) => api.acceptInvitationByTeamId(teamId));
      sessionStorage.setItem(constants.HIDE_ERROR_MSG, "joinTeamByIds");
      Promise.allSettled(promises)
        .then((results) => {
          const successes = results.filter((result) => result.status === "fulfilled");
          Log.debug("batch join team by id successes: ", successes);
          const failures = results.filter(
            (result): result is PromiseRejectedResult => result.status === "rejected"
          );
          Log.debug("batch join team by id failures: ", failures);
          selectWorkspaceProcess();
          if (successes.length === results.length) {
            const res = `Workspace${successes.length > 1 ? "s" : ""} joined successfully.`;

            message.success(res);
            setTimeout(() => {
              resolve(true);
            }, 500);
          } else if (failures.length === results.length) {
            const res = `Unable to join workspace${failures.length > 1 ? "s. Invitations have " : ". The invitation has "}expired.`;
            const showMsg =
              failures.filter((failure) => failure.reason?.response?.status === 404).length ===
              failures.length;
            if (showMsg) {
              message.error(res);
            }
          } else {
            const res = "Unable to join all workspaces. Some invitations have expired.";
            message.warning(res);
          }
        })
        .catch((err) => {
          Log.error("batch join team by id list err: ", err);
        })
        .finally(() => {
          sessionStorage.removeItem(constants.HIDE_ERROR_MSG);
          setTimeout(() => {
            resolve(false);
          }, 500);
        });
    });

  // join with code provided by email
  const joinTeamWithTeamId = (joinWorkspaceInfo: IJoinTeamInfo) =>
    new Promise<boolean>((resolve) => {
      let joinSuccess = false;
      const { code, teamId } = joinWorkspaceInfo;
      Log.debug("joinWorkspaceInfo: ", joinWorkspaceInfo);
      setJoinTeamInfo(joinTeamInfo);
      sessionStorage.setItem(constants.HIDE_ERROR_MSG, "joinTeamError");

      api
        .joinTeamWithId(teamId, code)
        .then((res) => {
          Log.debug("join team by team id  res: ", res);
          joinSuccess = true;
          navigate(routes.joinTeamSuccess);
        })
        .catch((err) => {
          Log.error("join Team With Id res err: ", err);
          sessionStorage.removeItem(constants.JOIN_TEAM_INFO);
          navigate(routes.joinTeamError);
        })
        .finally(() => {
          resolve(joinSuccess);
          setLoading(false);
        });
    });

  const signWithWorkspaceSelection = () => {
    Log.debug("sign With Workspace Selection");
    const queryWorkspaceId = getQueryValueByKeys(constants.TEAM_ID);
    Log.debug("query contains workspace id", queryWorkspaceId);
    const lastSelectedWorkspaceId = localStorage.getItem(constants.TEAM_ID);
    Log.debug("last selected workspace id", lastSelectedWorkspaceId);

    return Promise.all([api.getTeamsInfo(), api.getTeamInvitationList()]).then(
      ([teamList, teamInvitation]) => {
        Log.debug("get teamInfoList: ", teamList);
        Log.debug("get teamInvitation: ", teamInvitation);
        const teamInfoList = teamList || [];
        const teamInvitationList = teamInvitation || [];
        const teamLength = teamInfoList.length;
        const invitationLength = teamInvitationList.length;
        if (!teamLength && !invitationLength) {
          if (isCurrentEmailUnderRegistration(email)) {
            navigate(routes.createWorkspace);
          } else {
            navigate(routes.noWorkspaceLoading);
          }
          setLoading(false);
          return;
        }
        if (queryWorkspaceId && teamInfoList.find((team) => team.teamId === queryWorkspaceId)) {
          Log.debug("use query assigned workspace", queryWorkspaceId);
          redirectToAppWithAuthParams({ teamId: queryWorkspaceId });
          return;
        }
        if (
          lastSelectedWorkspaceId &&
          !invitationLength &&
          teamInfoList.find((team) => team.teamId === lastSelectedWorkspaceId)
        ) {
          Log.debug("use last selected workspaceId: ", lastSelectedWorkspaceId);
          redirectToAppWithAuthParams({});
          return;
        }
        if (teamLength === 1 && !invitationLength) {
          Log.debug("only one workspaceId: ", teamInfoList[0]?.teamId);
          redirectToAppWithAuthParams({ teamId: teamInfoList[0]?.teamId });
          return;
        }
        teamInfoList.map((team) => {
          const teamInfo = team;
          if (teamInfo.personal) {
            teamInfo.displayName = "Personal's space";
          }
          return teamInfo;
        });
        setTeamInfoList(teamInfoList);
        setTeamInvitationList(teamInvitationList);
        navigate(routes.selectWorkspace);
        setLoading(false);
      }
    );
  };

  const signWithAdminWorkspaceSelection = () => {
    Log.debug("sign With Admin Workspace Selection");
    const queryWorkspaceId = getQueryValueByKeys(constants.TEAM_ID);
    Log.debug("query contains workspace id", queryWorkspaceId);
    const lastSelectedWorkspaceId = localStorage.getItem(constants.TEAM_ID);
    Log.debug("last selected workspace id", lastSelectedWorkspaceId);

    return Promise.all([api.getTeamsInfo(), api.getTeamInvitationList()])
      .then(([teamList, teamInvitation]) => {
        Log.debug("get teamInfoList: ", teamList);
        Log.debug("get teamInvitation: ", teamInvitation);
        const teamInfoList = teamList || [];
        const teamInvitationList = teamInvitation || [];
        const teamLength = teamInfoList.length;
        const invitationLength = teamInvitationList.length;
        if (!teamLength && !invitationLength) {
          if (isCurrentEmailUnderRegistration(email)) {
            navigate(routes.createWorkspace);
          } else {
            navigate(routes.noWorkspaceLoading);
          }
          setLoading(false);
          return;
        }
        if (!invitationLength && teamLength === 1 && teamInfoList[0]?.personal) {
          setInvalidTypes(constants.NO_AVAILABLE_WORKSPACE);
          navigate(routes.login);
          setLoading(false);
          return;
        }
        const AdminTeamInfoList = teamInfoList.filter((team) => {
          const isTeamWorkspace = /team./.test(team.teamId);
          return isTeamWorkspace && isAdminRole(team.role);
        });
        const AdminTeamInvitationList = teamInvitationList.filter((team) => {
          const isTeamWorkspace = /team./.test(team.teamId);
          return isTeamWorkspace && isAdminRole(team.role);
        });
        if (!AdminTeamInfoList.length && !AdminTeamInvitationList.length) {
          setInvalidTypes(constants.INVALID_ACCOUNT);
          navigate(routes.login);
          setLoading(false);
          return;
        }
        if (
          queryWorkspaceId &&
          AdminTeamInvitationList.find((team) => team.teamId === queryWorkspaceId)
        ) {
          Log.debug("use query assigned workspace", queryWorkspaceId);
          redirectToAppWithAuthParams({ teamId: queryWorkspaceId });
          // setLoading(false);
          return;
        }
        if (
          lastSelectedWorkspaceId &&
          !AdminTeamInvitationList.length &&
          AdminTeamInfoList.find((team) => team.teamId === lastSelectedWorkspaceId)
        ) {
          redirectToAppWithAuthParams({});
          // setLoading(false);
          return;
        }
        if (AdminTeamInfoList.length === 1 && !AdminTeamInvitationList.length) {
          Log.debug("only one workspaceId: ", teamInfoList[0]?.teamId);
          redirectToAppWithAuthParams({ teamId: teamInfoList[0]?.teamId });
          return;
        }

        setTeamInfoList(AdminTeamInfoList || []);
        setTeamInvitationList(AdminTeamInvitationList || []);
        navigate(routes.selectWorkspace);
        setLoading(false);
      })
      .catch((error) => {
        Log.error("get teams info and invitation error: ", error);
        setLoading(false);
        navigate(routes.unknownError);
        setLoading(false);
      });
  };

  const selectWorkspaceProcess = () => {
    Log.debug("joinTeamInfo: ", joinTeamInfo);
    let joinTeamInfoRes = joinTeamInfo;
    if (!joinTeamInfoRes?.code) {
      const infos = sessionStorage.getItem(constants.JOIN_TEAM_INFO);
      const joinTeamInfoParse = infos && JSON.parse(infos);
      joinTeamInfoRes = joinTeamInfoParse;
    }
    if (joinTeamInfoRes?.code && joinTeamInfoRes?.teamId) {
      // skip if already joined
      api.getTeamsInfo().then((joinedTeams) => {
        Log.debug(`get joined teams: ${joinedTeams}`);
        if (joinedTeams?.find((team) => team.teamId === joinTeamInfoRes.teamId)) {
          message.success("You have already joined this workspace!");
          navigate(routes.joinTeamSuccess);
          setLoading(false);
        } else {
          joinTeamWithTeamId(joinTeamInfoRes);
        }
      });
      return;
    }
    const isSignInAdmin = signInFrom === "admin";
    Log.debug(" select isSignInAdmin: ", isSignInAdmin);

    if (isSignInAdmin) {
      return signWithAdminWorkspaceSelection();
    }
    return signWithWorkspaceSelection();
  };

  const registerApproval = (username: string, checkCode: string, registerCode: string) =>
    new Promise<boolean>((resolve) => {
      setUsername(username);
      setLoading(true);
      let isApproved = false;
      api
        .register(username, checkCode, registerCode)
        .then(() => {
          isApproved = true;
          Log.debug("register res");
        })
        .catch((error) => {
          Log.error("user email register error: ", error);
        })
        .finally(() => {
          resolve(isApproved);
          setLoading(false);
        });
    });

  const boardRegisterApproval = (username: string, requestId: string) =>
    new Promise<boolean>((resolve) => {
      setUsername(username);
      setLoading(true);
      let isApproved = false;
      api
        .tvRegister(username, requestId)
        .then(() => {
          isApproved = true;
          Log.debug("tv register res");
        })
        .catch((error) => {
          Log.error("user email register error: ", error);
        })
        .finally(() => {
          resolve(isApproved);
          setLoading(false);
        });
    });

  const emailApproval = (checkCode: string, registerCode: string, newPassword?: string) =>
    new Promise<boolean>((resolve) => {
      let hasApproved = false;
      setLoading(true);
      return api
        .emailApproval(checkCode, registerCode, newPassword)
        .then(() => {
          hasApproved = true;
          Log.debug("email Approved");
        })
        .catch((error) => {
          const errorMsg = "email Approval error: " + error;
          Log.error(errorMsg);
        })
        .finally(() => {
          setLoading(false);
          resolve(hasApproved);
        });
    });

  const emailBoardApproval = (requestId: string, resolveKey: string) =>
    new Promise<boolean>((resolve) => {
      let hasApproved = false;
      setLoading(true);
      return api
        .resolveImpersonate(requestId, resolveKey)
        .then(() => {
          hasApproved = true;
        })
        .catch((error) => {
          const errorMsg = "email approval error: " + error;
          Log.error(errorMsg);
        })
        .finally(() => {
          setLoading(false);
          resolve(hasApproved);
        });
    });

  const createWorkspace = (value: string) =>
    new Promise<string>((resolve) => {
      let teamId = "";
      api
        .createWorkspaceByName(value)
        .then((res) => {
          Log.debug("update workspace name success res:", res);
          teamId = res?.team_id || "";
          if (!teamId) {
            throw Error("Failed to create a workspace");
          }
        })
        .catch((error) => {
          Log.error("update workspace name error: ", error);
        })
        .finally(() => {
          resolve(teamId);
        });
    });

  const authWithLocalAccessToken = (isCheckToken: boolean) => {
    const localAccessToken =
      localStorage.getItem(constants.ACCESS_TOKEN) || getQueryValueByKeys(constants.ACCESS_TOKEN);
    if (localAccessToken) {
      Log.debug("login and auth with access_token: ", localAccessToken);
      sessionStorage.setItem(constants.HIDE_ERROR_MSG, "reauth");
      authUser(localAccessToken).then((isAuthed) => {
        if (!isAuthed) {
          Log.debug("auth With Local Access Token failed and redirect to login");
          localStorage.removeItem(constants.ACCESS_TOKEN);
          if (isCheckToken) {
            redirectToAppWithAuthParams({ noValidAccessToken: true });
          } else {
            navigate(routes.login);
          }
        }
      });
    } else if (isCheckToken) {
      Log.debug("redirect back without valid token");
      redirectToAppWithAuthParams({ noValidAccessToken: true });
    } else {
      Log.debug("redirect to login");
      navigate(routes.login);
    }
  };

  const tryJoinTeamInfo = async (joinTeamInfo: IJoinTeamInfo, isRegister: boolean) => {
    const email = joinTeamInfo.email;
    const teamId = joinTeamInfo.teamId;
    setEmail(email);
    setJoinTeamInfo(joinTeamInfo);
    const joinTeamInfoStringy = JSON.stringify(joinTeamInfo);
    sessionStorage.setItem(constants.JOIN_TEAM_INFO, joinTeamInfoStringy);
    sessionStorage.setItem(constants.HIDE_ERROR_MSG, "joinTeamTryAuth");
    try {
      if (!isRegister) {
        return;
      }
      setLoading(true);
      // try auth before join
      const localAccessToken = localStorage.getItem(constants.ACCESS_TOKEN);
      if (!localAccessToken) {
        setLoading(false);
        return;
      }
      const authRes = await authWithToken(localAccessToken);
      const isAuthed = authRes?.user?.email === email;
      Log.debug(`registerAndJoinTeam ${isAuthed ? "isAuthed" : "notAuthed"}`);
      if (!isAuthed) {
        setLoading(false);
        return;
      }
      // skip if already joined
      const joinedTeams = await api.getTeamsInfo();
      Log.debug(`get joined teams: ${joinedTeams}`);
      if (joinedTeams?.find((team) => team.teamId === teamId)) {
        message.success("You have already joined this workspace!");
        navigate(routes.joinTeamSuccess);
        setLoading(false);
        return;
      }

      try {
        sessionStorage.setItem(constants.HIDE_ERROR_MSG, "joinTeamById");
        await api.acceptInvitationByTeamId(teamId);
        Log.debug("You have successfully joined: ", teamId);
        navigate(routes.joinTeamSuccess);
      } catch (e) {
        Log.error("failed to join workspace", teamId, e);
        sessionStorage.removeItem(constants.JOIN_TEAM_INFO);
        navigate(routes.joinTeamError);
      } finally {
        sessionStorage.removeItem(constants.HIDE_ERROR_MSG);
        setLoading(false);
      }
    } catch (e) {
      Log.error("save team error: ", e);
      setLoading(false);
    }
  };

  const logout = () => {
    Log.debug("Vibe-One logout");
    localStorage.removeItem(constants.ACCESS_TOKEN);
    if (signInFrom === "one") {
      Log.debug("logout redirectUrl: ", redirectUrl);
      window.location.replace(redirectUrl);
    } else {
      navigate(routes.login);
    }
  };

  const signInWithPassword = (email: string, password: string) =>
    new Promise<boolean>((resolve) => {
      let isAuthed = false;
      sessionStorage.setItem(constants.HIDE_ERROR_MSG, "signginwithpsw");
      api
        .signInWithPassword(email, password, getCaptchaToken())
        .then((res) => {
          Log.debug("sign In With Password: ", res);
          if (res?.access_token) {
            isAuthed = true;
            setLoading(true);
            authUser(res.access_token);
          }
        })
        .catch((error) => {
          Log.error("sign In With Password error: ", error);
          setLoading(false);
        })
        .finally(() => {
          resetCaptchaToken();
          sessionStorage.removeItem(constants.HIDE_ERROR_MSG);
          resolve(isAuthed);
        });
    });

  const requestResetPassword = (email: string) =>
    new Promise<void>((resolve) => {
      setEmail(email);
      api
        .requestResetPassword(email, getCaptchaToken())
        .then((res) => {
          message.success("The email is on the way. Please check your inbox.");
          Log.debug("request Reset Password", res);
          resolve();
        })
        .catch((error) => {
          message.error("Resend failed! Please check your email.");
          Log.error("request Reset Password error: ", error);
        })
        .finally(() => {
          resetCaptchaToken();
        });
    });

  const resetPassword = (email: string, requestId: string, newPassword: string) =>
    new Promise<boolean>((resolve) => {
      let resetSuccess = false;
      sessionStorage.setItem(constants.HIDE_ERROR_MSG, "resetPassword");
      api
        .resetPassword(email, requestId, newPassword)
        .then(() => {
          Log.debug("reset Password success");
          resetSuccess = true;
        })
        .catch((error) => {
          Log.error("reset Password error: ", error);
        })
        .finally(() => {
          resolve(resetSuccess);
          sessionStorage.removeItem(constants.HIDE_ERROR_MSG);
        });
    });

  const getAdminWorkspaceSelection = () =>
    new Promise<ITeamInfoObj[]>((resolve) => {
      let teamList = [] as ITeamInfoObj[];
      setLoading(true);
      api
        .ping()
        .then((res) => {
          if (!res?.csrf_token) {
            Log.error("ping failed");
            message.error("Failed to access valid admin workspace!");
            navigate(routes.unknownError);
            setLoading(false);
            return;
          }
          sessionStorage.setItem(constants.X_CSRF_TOKEN, res.csrf_token);
          api
            .getTeamsInfo()
            .then((teamsInfo) => {
              let teamInfoList = teamsInfo || [];
              Log.debug("getTeamsInfo res teamInfoList: ", teamInfoList);
              if (!teamInfoList?.length) {
                message.error("Failed to access valid admin workspace!");
                navigate(routes.unknownError);
                return;
              }
              teamInfoList =
                teamInfoList.filter((team) => {
                  const isTeamWorkspace = /team./.test(team.teamId);
                  return isTeamWorkspace && isAdminRole(team.role);
                }) || [];
              if (!teamInfoList?.length) {
                setInvalidTypes(constants.INVALID_ACCOUNT);
                setLoading(false);
                navigate(routes.login);
                return;
              }
              teamList = teamInfoList;
            })
            .catch((err) => {
              Log.error("get Admin Workspace Selection err: ", err);
              navigate(routes.unknownError);
            })
            .finally(() => {
              setLoading(false);
              Log.debug("get Admin Workspace Selection teamList: ", teamList);
              resolve(teamList);
            });
        })
        .catch((error) => {
          Log.error("ping error: ", error);
          navigate(routes.unknownError);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const checkEmailRegistered = (email: string) =>
    new Promise<boolean>((resolve) => {
      let isRegisteredAccount = false;
      setEmail(email);
      setLoading(true);
      api
        .userLoginStatus(email, getCaptchaToken())
        .then((res) => {
          if (res?.registered) {
            isRegisteredAccount = true;
            Log.debug("check email is registered:", email);
            return;
          }
        })
        .catch((error) => Log.error("check email registered: ", error))
        .finally(() => {
          setLoading(false);
          resolve(isRegisteredAccount);
          resetCaptchaToken();
        });
    });

  const trySignInWithSSO = (email: string) =>
    new Promise<boolean>((resolve) => {
      let unregisteredAccount = false;
      setEmail(email);
      sessionStorage.setItem(constants.EMAIL_KEY, email);
      api
        .userLoginStatus(email, getCaptchaToken())
        .then((res) => {
          if (!res?.registered) {
            unregisteredAccount = true;
            Log.debug("email address is not registered:", email);
            return;
          }
          if (res?.sso_url) {
            const ssoFromUrl = window.location.origin;
            const ssoRedirectUrl = `${res.sso_url}?from=${ssoFromUrl}`;
            Log.debug("ssoRedirectUrl: ", ssoRedirectUrl);
            setSSOUrl(ssoRedirectUrl);
            navigate(routes.ssoCheck);
            return;
          }
          navigate(routes.loginWithCode);
        })
        .catch((error) => Log.error("try sign in with SSO error: ", error))
        .finally(() => {
          resolve(unregisteredAccount);
          resetCaptchaToken();
        });
    });

  const requestLoginCode = (email?: string, isResend?: boolean) =>
    new Promise<boolean>((resolve) => {
      let requestRes = false;
      if (!email) {
        Log.debug("missing user's information, retry");
        resolve(requestRes);
        navigate(routes.login);
        return;
      }
      !isResend && setLoading(true);
      setEmail(email);
      api
        .getLoginCode(email, getCaptchaToken())
        .then((res) => {
          Log.debug("get login check_code res: ", res);
          if (!res?.check_code) {
            Log.error("missing check code");
            navigate(routes.login);
            return;
          }
          sessionStorage.setItem(constants.CHECK_CODE, res.check_code);
          setCheckCode(res.check_code);
          requestRes = true;
          !isResend && navigate(routes.checkLoginCode);
        })
        .catch((error) => {
          Log.error("get login check_code error: ", error);
          navigate(routes.login);
        })
        .finally(() => {
          setLoading(false);
          resetCaptchaToken();
          resolve(requestRes);
        });
    });

  const checkLoginCode = async (registerCode: string): Promise<TInputInValidStatus> => {
    let codeCheckRes = INPUT_INVALID_STATUS.INITIAL;
    const savedCheckCode = checkCode || sessionStorage.getItem(constants.CHECK_CODE);

    if (!savedCheckCode || !registerCode) {
      Log.debug("missing user's information, retry");
      navigate(routes.login);
      return Promise.resolve(codeCheckRes);
    }

    try {
      sessionStorage.setItem(constants.HIDE_ERROR_MSG, "checkLoginCode");
      const loginRes = await api.loginWithCode(savedCheckCode, registerCode);
      Log.debug("login with code res: ", loginRes);
      sessionStorage.removeItem(constants.CHECK_CODE);
      sessionStorage.removeItem(constants.HIDE_ERROR_MSG);
      setCheckCode("");

      if (!loginRes?.access_token) {
        Log.error("invalid login code");
        navigate(routes.login);
        return Promise.resolve(codeCheckRes);
      }

      setLoading(true);

      const authRes = await authWithToken(loginRes.access_token);
      Log.debug("auth With Token res: ", authRes);

      if (!authRes?.csrf_token) {
        Log.error("access token auth failed?");
        navigate(routes.login);
        setLoading(false);
        return Promise.resolve(codeCheckRes);
      }

      const userRes = await api.getUserInfo();
      Log.debug("get user info res: ", userRes);

      const skipSetPassword = localStorage.getItem(constants.SKIP_SET_PASSWORD) === email;

      if (!userRes?.has_password && !skipSetPassword) {
        setLoading(false);
        navigate(routes.setPassword);
        return Promise.resolve(codeCheckRes);
      }

      await selectWorkspaceProcess();
      return Promise.resolve(codeCheckRes);
    } catch (error) {
      Log.error("check login code error: ", error);
      setLoading(false);

      if ((error as AxiosError)?.response?.status === 404) {
        codeCheckRes = INPUT_INVALID_STATUS.INVALID;
        Log.debug("Invalid Login Code");
      }
      return Promise.resolve(codeCheckRes);
    }
  };

  const addNewPassword = (newPassword: string) =>
    new Promise<boolean>((resolve) => {
      let addRes = false;
      const oldPassword = "";
      api
        .updatePassword(oldPassword, newPassword)
        .then(() => {
          addRes = true;
        })
        .catch((error) => {
          Log.error("add password error: ", error);
          message.error("Set password failed");
        })
        .finally(() => {
          resolve(addRes);
        });
    });

  const authState = {
    SSOUrl,
    email,
    username,
    password,
    invalidTypes,
    checkCode,
    joinTeamInfo,
  };

  const contextValue = {
    authState,
    isLoading,
    signInFrom,
    redirectUrl,
    teamInfoList,
    teamInvitationList,
    redirectToAppWithAuthParams,
    authWithLocalAccessToken,
    authUser,
    registerUserWithPassword,
    checkRegistrationCode,
    registerApproval,
    boardRegisterApproval,
    emailApproval,
    emailBoardApproval,
    createWorkspace,
    tryJoinTeamInfo,
    joinWorkspacesByTeamIds,
    logout,
    requestResetPassword,
    signInWithPassword,
    resetPassword,
    getAdminWorkspaceSelection,
    checkEmailRegistered,
    trySignInWithSSO,
    checkLoginCode,
    requestLoginCode,
    selectWorkspaceProcess,
    addNewPassword,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}
