import React, { useEffect } from "react";

import { SubmitEmail } from "@/components/CustomForm";
import VibeTitle from "@/components/VibeTitle";
import Link from "@/components/CustomLink";
import constants, { routes } from "@/constants";
import { useAuth, useRedirectNavigation } from "@/hooks";
import CustomLayout from "@/components/CustomLayout";

const RequestResetPassword = ({ onSentSuccess }: { onSentSuccess: VoidFunction }) => {
  const auth = useAuth();
  const navigate = useRedirectNavigation();
  const prefilledEmail =
    auth?.authState?.email || sessionStorage.getItem(constants.EMAIL_KEY) || "";

  useEffect(() => {
    if (!prefilledEmail) {
      navigate(routes.login);
    }
  }, [prefilledEmail]);

  function handleContinueClick(email: string) {
    auth.requestResetPassword(email);
    onSentSuccess();
  }

  return (
    <CustomLayout>
      <VibeTitle title="Forgot your password?" subtitle="Enter your Vibe account email address" />
      <SubmitEmail
        disableEmailModify
        prefilledEmail={prefilledEmail}
        onSubmit={handleContinueClick}
      />
      <div className="bottom-link-wrapper password-links-wrapper">
        <Link className="link" to={routes.login}>
          Back to sign in
        </Link>
      </div>
    </CustomLayout>
  );
};

export default RequestResetPassword;
