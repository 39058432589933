import React, { useRef, useState, useEffect } from "react";

import CustomInput, {
  INPUT_INVALID_STATUS,
  TInputInValidStatus,
  VALIDATION_RULES,
} from "../CustomInput";
import { emailInvalidHint } from "./invalidInputHints";
import LoadingButton from "../LoadingButton";

const SubmitEmail = ({
  prefilledEmail,
  disableEmailModify,
  loading = false,
  onSubmit,
  errorHint,
}: {
  prefilledEmail?: string;
  disableEmailModify?: boolean;
  loading?: boolean;
  onSubmit: (email: string) => void;
  errorHint?: React.ReactNode;
}) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>(prefilledEmail || "");
  const [invalidStatus, setInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );

  function testInputValidation(email: string) {
    if (!email) {
      setInvalidStatus(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    if (!VALIDATION_RULES.EMAIL_REGEX_PATTERN.test(email)) {
      setInvalidStatus(INPUT_INVALID_STATUS.INVALID);
      return false;
    }
    setInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function handleEmailInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const email = e?.target?.value?.toLowerCase() || "";
    setEmail(email);
    if (invalidStatus !== INPUT_INVALID_STATUS.INITIAL) {
      testInputValidation(email.trim());
    }
  }

  function handleEmailFinished() {
    if (testInputValidation(email.trim())) {
      setEmail(email.trim());
      onSubmit(email.trim());
    }
  }

  useEffect(() => {
    emailInputRef?.current?.focus();
  }, []);

  return (
    <>
      <CustomInput
        inputRef={emailInputRef}
        type="email"
        value={email}
        disabled={disableEmailModify && !!prefilledEmail}
        onChange={handleEmailInputChange}
        onEnter={handleEmailFinished}
        invalidHintContent={errorHint || emailInvalidHint(invalidStatus)}
      />
      <LoadingButton
        className="button-primary-360"
        onClick={handleEmailFinished}
        isLoading={loading}
      >
        Continue
      </LoadingButton>
    </>
  );
};

export default SubmitEmail;
