import React, { useEffect, useMemo, useState } from "react";
import { useAuth, useRedirectNavigation } from "@/hooks";
import { message } from "antd";
import constants, { routes } from "@/constants";
import VibeTitle from "@/components/VibeTitle";
import { SubmitCode } from "@/components/CustomForm";
import { INPUT_INVALID_STATUS, TInputInValidStatus } from "@/components/CustomInput";
import Log from "@/utils/Log";
import CustomLayout from "@/components/CustomLayout";

const initialRegisterInfo = {
  email: "",
  username: "",
  password: "",
  checkCode: "",
};

const CheckRegisterOTPCode = () => {
  const [invalidStatus, setInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const navigate = useRedirectNavigation();
  const sessionRegisterInfo = sessionStorage.getItem(constants.REGISTRATION_INFO);
  Log.debug("sessionRegisterInfo: ", sessionRegisterInfo);
  const { email, username, password, checkCode } = sessionRegisterInfo
    ? JSON.parse(sessionRegisterInfo)
    : initialRegisterInfo;

  useEffect(() => {
    if (!email || !username || !password || !checkCode) {
      Log.debug("missing user's information, please retry!");
      navigate(routes.register);
    }
  }, []);

  function handleSubmitCode(code: string) {
    Log.debug("registration code: ", code);
    setIsLoading(true);
    console.log("checkCode: ", checkCode);
    setInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
    auth
      .checkRegistrationCode(email, username, password, checkCode, code)
      .then((checkRes: TInputInValidStatus) => {
        setInvalidStatus(checkRes);
        checkRes === INPUT_INVALID_STATUS.INVALID && setIsLoading(false);
      });
  }

  function handleResendEmail() {
    auth.registerUserWithPassword(email, username, password).then((isSent) => {
      if (isSent) {
        message.success("The email is on the way. Please check your inbox.");
        setInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
      } else {
        message.error("Resend failed!");
      }
    });
  }

  const subtitle = useMemo(
    () => (
      <>
        <p>
          A verification code has been sent to <em className="em-email">{email + "."}</em>
        </p>
        <p>
          Please check your inbox and enter the code below to verify your email address.
          <em className="em-text">&nbsp;The code expires in 10 minutes.</em>
        </p>
      </>
    ),
    [email]
  );

  return (
    <CustomLayout contentClassName="check-opt-code-wrapper">
      <VibeTitle title="Verify your email address" subtitle={subtitle} />
      <SubmitCode
        key={checkCode}
        isLoading={isLoading}
        invalidStatus={invalidStatus}
        onSubmit={handleSubmitCode}
      />
      <div className="bottom-link-wrapper">
        <p className="resend-text">
          Didn&apos;t receive an email?&nbsp;
          <span className="link-text title" onClick={handleResendEmail}>
            Resend code.
          </span>
        </p>
      </div>
    </CustomLayout>
  );
};

export default CheckRegisterOTPCode;
