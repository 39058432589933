import React, { ReactNode } from "react";

import VibeLogo from "./VibeLogo";
import "./styles.css";

interface Props {
  className?: string;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  icon?: ReactNode;
}

const VibeTitle = (props: Props) => {
  const { title, subtitle, className = "", icon = VibeLogo } = props;
  return (
    <div className={"title-wrapper " + className}>
      <div className="icon">{icon}</div>
      {title && <h2 className="title title-size">{title}</h2>}
      {subtitle && <div className="subtitle text-align-center">{subtitle}</div>}
    </div>
  );
};

export default VibeTitle;
