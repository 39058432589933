import React from "react";

const VibeOneIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6092 15.6001C12.9386 16.7906 12.5447 18.1948 12.5447 19.6655C12.5447 24.1062 16.1165 27.733 20.5356 27.733V33.3309C13.0438 33.3309 7 27.1873 7 19.6655C7 17.1903 7.66071 14.8366 8.7892 12.833L13.6092 15.6001Z"
      fill="url(#paint0_linear_793_61043)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.4664 19.6659C28.4664 18.1381 28.0912 16.784 27.4224 15.6361L32.2016 12.7979C33.3939 14.8442 34.0112 17.1853 34.0112 19.6659C34.0112 27.2243 27.9622 33.3314 20.4756 33.3314V27.7334C24.8999 27.7334 28.4664 24.1327 28.4664 19.6659Z"
      fill="url(#paint1_linear_793_61043)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5066 11.5979C17.5581 11.5979 14.9842 13.1974 13.6026 15.6153L8.7998 12.8179C11.1403 8.72212 15.5144 6 20.5066 6C25.5109 6 29.8741 8.76365 32.2075 12.8077L27.4164 15.6254C26.0276 13.2185 23.4428 11.5979 20.5066 11.5979Z"
      fill="url(#paint2_linear_793_61043)"
    />
    <path
      d="M11.2014 17.0166C12.7333 17.0166 13.9751 15.7629 13.9751 14.2163C13.9751 12.6697 12.7333 11.416 11.2014 11.416C9.66955 11.416 8.42773 12.6697 8.42773 14.2163C8.42773 15.7629 9.66955 17.0166 11.2014 17.0166Z"
      fill="#66CBCB"
    />
    <path
      d="M29.8117 17.0166C31.3436 17.0166 32.5854 15.7628 32.5854 14.2163C32.5854 12.6697 31.3436 11.416 29.8117 11.416C28.2799 11.416 27.0381 12.6697 27.0381 14.2163C27.0381 15.7628 28.2799 17.0166 29.8117 17.0166Z"
      fill="#FF6666"
    />
    <path
      d="M20.5061 33.334C22.0364 33.334 23.2769 32.0816 23.2769 30.5366C23.2769 28.9917 22.0364 27.7393 20.5061 27.7393C18.9759 27.7393 17.7354 28.9917 17.7354 30.5366C17.7354 32.0816 18.9759 33.334 20.5061 33.334Z"
      fill="#6666CC"
    />
    <path
      d="M29.8123 16.3458C30.9772 16.3458 31.9216 15.3924 31.9216 14.2164C31.9216 13.0403 30.9772 12.0869 29.8123 12.0869C28.6475 12.0869 27.7031 13.0403 27.7031 14.2164C27.7031 15.3924 28.6475 16.3458 29.8123 16.3458Z"
      fill="#FFCC00"
    />
    <path
      d="M11.1707 16.3458C12.3356 16.3458 13.28 15.3924 13.28 14.2164C13.28 13.0403 12.3356 12.0869 11.1707 12.0869C10.0059 12.0869 9.06152 13.0403 9.06152 14.2164C9.06152 15.3924 10.0059 16.3458 11.1707 16.3458Z"
      fill="#FFCC00"
    />
    <path
      d="M20.5067 32.6613C21.6716 32.6613 22.6159 31.7079 22.6159 30.5318C22.6159 29.3557 21.6716 28.4023 20.5067 28.4023C19.3418 28.4023 18.3975 29.3557 18.3975 30.5318C18.3975 31.7079 19.3418 32.6613 20.5067 32.6613Z"
      fill="#FFCC00"
    />
    <defs>
      <linearGradient
        id="paint0_linear_793_61043"
        x1="9.88325"
        y1="21.958"
        x2="12.078"
        y2="12.345"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6666CC" />
        <stop offset="1" stopColor="#6666CC" stopOpacity="0.3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_793_61043"
        x1="27.2434"
        y1="29.1009"
        x2="18.4358"
        y2="33.2674"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6666" />
        <stop offset="1" stopColor="#FF6666" stopOpacity="0.3" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_793_61043"
        x1="23.8945"
        y1="10.8127"
        x2="29.2067"
        y2="17.8299"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#66CCCC" />
        <stop offset="1" stopColor="#66CCCC" stopOpacity="0.3" />
      </linearGradient>
    </defs>
  </svg>
);

export default VibeOneIcon;
