import React, { useEffect, useMemo, useState } from "react";
import { message } from "antd";
import constants, { routes } from "@/constants";
import Log from "@/utils/Log";
import { useAuth, useRedirectNavigation } from "@/hooks";
import VibeTitle from "@/components/VibeTitle";
import { SubmitCode } from "@/components/CustomForm";
import { INPUT_INVALID_STATUS, TInputInValidStatus } from "@/components/CustomInput";
import CustomLayout from "@/components/CustomLayout";

const CheckLoginCode = () => {
  const [invalidStatus, setInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );
  const [isLoading, setIsLoading] = useState(false);
  const isResend = true;
  const auth = useAuth();
  const navigate = useRedirectNavigation();
  const email = auth?.authState?.email || sessionStorage.getItem(constants.EMAIL_KEY) || "";
  const checkCode =
    auth?.authState?.checkCode || sessionStorage.getItem(constants.CHECK_CODE) || "";

  useEffect(() => {
    if (!email || !checkCode) {
      Log.info("missing user's information, please retry!");
      navigate(routes.login);
    }
  }, [email]);

  function handleSubmitCode(code: string) {
    Log.debug("login check code: ", code);
    setIsLoading(true);
    setInvalidStatus(INPUT_INVALID_STATUS.INITIAL);

    auth.checkLoginCode(code).then((codeCheckRes) => {
      Log.debug("check Login Code res: ", codeCheckRes);
      setInvalidStatus(codeCheckRes);
      setIsLoading(false);
    });
  }

  function handleResendCode() {
    auth.requestLoginCode(email, isResend).then((isSent) => {
      if (isSent) {
        message.success("The email is on the way. Please check your inbox.");
        setInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
      } else {
        message.error("Resend failed!");
      }
    });
  }

  const subtitle = useMemo(
    () => (
      <>
        <p>
          A verification code has been sent to <em className="em-email">{email + "."}</em>
        </p>
        <p>
          Please check your inbox and enter the code below to verify your email address.
          <em className="em-text">&nbsp;The code expires in 10 minutes.</em>
        </p>
      </>
    ),
    [email]
  );

  return (
    <CustomLayout contentClassName="check-opt-code-wrapper max-width-366">
      <VibeTitle title="Verify your email address" subtitle={subtitle} />
      <SubmitCode
        key={checkCode}
        isLoading={isLoading}
        invalidStatus={invalidStatus}
        onSubmit={handleSubmitCode}
      />
      <div className="bottom-link-wrapper">
        <p className="resend-text">
          Didn&apos;t receive an email?&nbsp;
          <span className="link-text title" onClick={handleResendCode}>
            Resend code.
          </span>
        </p>
      </div>
    </CustomLayout>
  );
};

export default CheckLoginCode;
