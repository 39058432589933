import React, { useEffect, useMemo } from "react";
import VibeTitle from "@/components/VibeTitle";
import constants, { routes } from "@/constants";
import { useAuth, useRedirectNavigation } from "@/hooks";
import Log from "@/utils/Log";

import CanvasIcon from "./assets/CanvasIcon";
import VibeOneIcon from "./assets/VibeOneIcon";
import CustomLayout from "@/components/CustomLayout";

const JoinTeamSuccess = () => {
  const navigate = useRedirectNavigation();
  const { canvas, one } = constants.SIGN_IN_APP_NAMES;
  const teamInfos = sessionStorage.getItem(constants.JOIN_TEAM_INFO);
  const joinTeamInfoParse = teamInfos && JSON.parse(teamInfos);
  const { teamName, teamId } = joinTeamInfoParse;

  const auth = useAuth();

  const redirectApps = [
    {
      app: canvas,
      icon: CanvasIcon,
      name: "Vibe Canvas",
      desc: "Online whiteboard",
    },
    {
      app: one,
      icon: VibeOneIcon,
      name: "Vibe One",
      desc: "Control boards from your device",
    },
  ];

  useEffect(() => {
    sessionStorage.removeItem(constants.JOIN_TEAM_INFO);
    if (!teamName || !teamId) {
      navigate(routes.joinTeamError);
      return;
    }
  }, []);

  function handleRedirectToApp(app: SignInApps) {
    Log.debug(`redirect app is ${app}`);
    sessionStorage.removeItem(constants.JOIN_TEAM_INFO);
    auth.redirectToAppWithAuthParams({ teamId, redirectApp: app });
    localStorage.setItem(constants.TEAM_ID, teamId);
  }

  const title = useMemo(
    () => (
      <>
        You have successfully joined <br /> {`"${teamName}".`}
      </>
    ),
    [teamName]
  );

  return (
    <CustomLayout contentClassName="join-success-page">
      <VibeTitle
        title={title}
        subtitle="You can now collaborate with the team across Vibe services."
      />
      <div>
        {redirectApps.map(({ app, name, icon, desc }) => (
          <div key={app} className="app-selection-item" onClick={() => handleRedirectToApp(app)}>
            {icon}
            <div>
              <div className="app-name">{name}</div>
              <div className="app-desc">{desc}</div>
            </div>
          </div>
        ))}
      </div>
    </CustomLayout>
  );
};

export default JoinTeamSuccess;
