import constants from "@/constants";
import { ITeamInfoObj } from "@/api/interface";
import Log from "./Log";

const roleOrder: Record<string, number> = {
  undefined: 0,
  owner: 1,
  admin: 2,
  editor: 3,
};

function compareTeamByRole(a: ITeamInfoObj, b: ITeamInfoObj) {
  return roleOrder[a.role || "undefined"] - roleOrder[b.role || "undefined"];
}

function compareTeamByRoleAndName(a: ITeamInfoObj, b: ITeamInfoObj) {
  if (a.role && !b.role) return 1;
  if (!a.role && b.role) return -1;
  return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
}

function compareTeamByInvitationTime(a: ITeamInfoObj, b: ITeamInfoObj) {
  return Number(a?.expireAt) - Number(b?.expireAt);
}

const getTimeString = function (timestamp: number): string {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  const utcOffsetMinutes = date.getTimezoneOffset();
  const utcOffsetHours = Math.abs(Math.floor(utcOffsetMinutes / 60));

  const utcOffsetSign = utcOffsetMinutes > 0 ? "-" : "+";
  const formattedUtcOffset = `${utcOffsetSign}${utcOffsetHours.toString()}`;

  return `${year}/${month}/${day} ${hours}:${minutes}:${second} UTC${formattedUtcOffset}`;
};

function getQueryValueByKeys(queryKeys: string | string[]) {
  const searchParams = new URLSearchParams(window.location.search);
  const keysArray = Array.isArray(queryKeys) ? queryKeys : [queryKeys];
  const infoValues = new Array(keysArray.length);

  const getTimeStringFromQuery = (timestamp: number): string => {
    if (!timestamp) return "";
    return getTimeString(timestamp * 1000);
  };

  for (const p of searchParams) {
    keysArray.forEach((key, index) => {
      if (key === p[0]) {
        if (key === "t") {
          infoValues[index] = getTimeStringFromQuery(Number(p[1])); // handle time values
        } else {
          infoValues[index] = p[1];
        }
      }
    });
  }

  if (Array.isArray(queryKeys)) {
    return infoValues as string[];
  } else {
    return infoValues[0] || "";
  }
}

function redirectParams() {
  const { REDIRECT_FROM, REDIRECT_TO } = constants;
  const redirectQueryKeys = [REDIRECT_FROM, REDIRECT_TO];
  const query = new URLSearchParams(window.location.search);
  const queryParams = new URLSearchParams();
  redirectQueryKeys.forEach((key) => {
    const q = query.get(key);
    if (q) {
      queryParams.append(key, q);
    }
  });
  return queryParams;
}

function addQueryParam(url: string, key: string, value: string): string {
  const separator = url.includes("?") ? "&" : "?";
  const updatedUrl = `${url}${separator}${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  return updatedUrl;
}

function addRedirectParam(url: string, teamId?: string, accessToken?: string): string {
  const { TEAM_ID, ACCESS_TOKEN } = constants;
  const separator = url.includes("?") ? "&" : "?";
  const teamIdQuery = teamId ? `${encodeURIComponent(TEAM_ID)}=${encodeURIComponent(teamId)}` : "";
  const connector = teamIdQuery ? "&" : "";
  const accessTokenQuery = accessToken
    ? `${encodeURIComponent(ACCESS_TOKEN)}=${encodeURIComponent(accessToken)}`
    : "";
  const updatedUrl = `${url}${separator}${teamIdQuery}${connector}${accessTokenQuery}`;
  return updatedUrl;
}
const originWhiteList = ["https://www.notion.so", "https://zoom.us"];
function allowIframe(): boolean | undefined {
  if (window.top === window.self) {
    return true;
  }

  const ancestorOrigins = window.location.ancestorOrigins;
  if (ancestorOrigins) {
    for (const origin of ancestorOrigins) {
      if (!originWhiteList.find((o) => o === origin)) {
        return false;
      }
    }

    return true;
  } else {
    // firefox don't support ancestorOrigins
  }
}

function envIsPord() {
  const currentEnv = (process.env.REACT_APP_APP_ENV as EnvType) || "dev";
  return currentEnv === "prod";
}
function allowDomain(redirectUrl: string) {
  let allowedDomains = ["vibe.us"];
  if (!envIsPord()) {
    allowedDomains = ["vibe-beta.com", "localhost"];
  }
  try {
    const url = new URL(decodeURIComponent(redirectUrl));
    const domain = url.hostname;

    for (const allowedDomain of allowedDomains) {
      if (domain === allowedDomain || domain.endsWith("." + allowedDomain)) {
        return true;
      }
    }
  } catch (error) {
    Log.error(`Invalid URL:${redirectUrl}`);
  }
  return false;
}

export {
  compareTeamByRole,
  compareTeamByRoleAndName,
  compareTeamByInvitationTime,
  getTimeString,
  getQueryValueByKeys,
  redirectParams,
  addQueryParam,
  addRedirectParam,
  allowIframe,
  allowDomain,
};
