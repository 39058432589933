import React, { useEffect } from "react";

import Loading from "@/components/Loading";
import { useAuth } from "@/hooks";
import constants from "@/constants";

const CorsLoginSync = ({ isCheckToken = false }: { isCheckToken?: boolean }) => {
  const auth = useAuth();
  useEffect(() => {
    sessionStorage.setItem(constants.HIDE_ERROR_MSG, "cors auth");
    auth.authWithLocalAccessToken(isCheckToken);
  }, []);

  return <Loading />;
};

export default CorsLoginSync;
