import { TCommonInputTypes } from "./interface";

export const commonInputTypes: Record<TCommonInputTypes, TCommonInputTypes> = {
  email: "email",
  password: "password",
  username: "username",
  text: "text",
};

// limit discussion about length
// https://vibeus.slack.com/archives/C05M9J5UUG0/p1732762807345029?thread_ts=1731917597.289449&cid=C05M9J5UUG0
const EMAIL_REGEX_PATTERN =
  /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const USERNAME_REGEX_PATTERN = /^.{1,70}$/;

const PASSWORD_MIN_PATTERN = /.{8,}/;
const PASSWORD_MAX_PATTERN = /^.{8,128}$/;
const PASSWORD_REGEX_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

const WORKSPACE_NAME_MAX_PATTERN = /^.{1,100}$/;

export const VALIDATION_RULES = {
  EMAIL_REGEX_PATTERN,
  USERNAME_REGEX_PATTERN,
  PASSWORD_MIN_PATTERN,
  PASSWORD_MAX_PATTERN,
  PASSWORD_REGEX_PATTERN,
  WORKSPACE_NAME_MAX_PATTERN,
};

export function getInputConfigs(type?: string) {
  const { email, username, password } = commonInputTypes;
  switch (type) {
    case email:
      return {
        name: "email",
        autoComplete: "email",
        placeholder: "Email",
      };
    case username:
      return {
        type: "text",
        name: "username",
        placeholder: "Name",
        autoComplete: "name",
      };
    case password:
      return {
        name: "password",
        placeholder: "Password",
        autoComplete: "current-password",
      };
    default:
      return;
      return {}; // Return an empty object as the default case
  }
}

export const passwordInvalidContent = [
  {
    pattern: /^.{8,128}$/,
    content: "Password should not exceed 128 characters",
  },
  {
    pattern: /^.{8,128}$/,
    content: "Password should not exceed 128 characters",
  },
  {
    pattern: /^(?=.*[A-Za-z])(?=.*\d).*$/,
    content: "Must use both letters and numbers",
  },
  {
    pattern: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
    content: "Must contain both lowercase and uppercase letters",
  },
];
