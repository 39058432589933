import React, { useState } from "react";
import RequestResetPassword from "./components/RequestResetPassword";
import ResendResetPassword from "./components/ResendResetPassword";

const ForgotPassword = () => {
  const [hasSendSuccess, setSentSuccess] = useState<boolean>(false);

  const handleSentSuccess = () => {
    setSentSuccess(true);
  };

  return hasSendSuccess ? (
    <ResendResetPassword />
  ) : (
    <RequestResetPassword onSentSuccess={handleSentSuccess} />
  );
};

export default ForgotPassword;
