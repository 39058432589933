import React, { memo } from "react";
import Link from "@/components/CustomLink";

import constants, { routes } from "@/constants";

const WarningIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#FCBD1B" />
    <path
      d="M8.80204 5.83485C8.77366 5.15367 9.31823 4.58594 10 4.58594C10.6818 4.58594 11.2263 5.15367 11.198 5.83485L10.9765 11.1492C10.9547 11.6728 10.524 12.0859 10 12.0859C9.47604 12.0859 9.04528 11.6728 9.02347 11.1492L8.80204 5.83485Z"
      fill="white"
    />
    <circle cx="10" cy="14.1719" r="1.25" fill="white" />
  </svg>
);

const InvalidAdminAccountHints = ({ openCreateLink }: { openCreateLink?: boolean }) => (
  <div className="invalid-admin-wrapper">
    <WarningIcon />
    <p>
      {"Sorry, we couldn't sign you in to your account. The Vibe Admin view is only available for "}
      <em>Workspace Owners</em> or <em>Workspace Admin</em> accounts.
      {openCreateLink && " You will need to first create a workspace."}
    </p>
  </div>
);

const BottomInvalidAdminHint = ({ type }: { type: string }) => {
  switch (type) {
    case constants.INVALID_ACCOUNT:
      return <InvalidAdminAccountHints />;
    case constants.NO_AVAILABLE_WORKSPACE:
      return (
        <>
          <InvalidAdminAccountHints openCreateLink />
          <Link className="create-workspace-link" to={routes.createWorkspace}>
            {"Create a team workspace"}
          </Link>
        </>
      );
    default:
      return null;
  }
};

export default memo(BottomInvalidAdminHint);
