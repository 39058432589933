import React from "react";

import Link from "@/components/CustomLink";
import VibeTitle from "@/components/VibeTitle";
import { useAuth } from "@/hooks/authHooks";
import { routes } from "@/constants";
import CustomLayout from "@/components/CustomLayout";

const subtitle = (email: string) => (
  <>
    If a Vibe account exists for <em className="em-email">{email + ","}</em> an email will be sent
    with the password reset link.
  </>
);

const ResendResetPassword = () => {
  const auth = useAuth();
  const email = auth?.authState?.email || "";

  function handleResendEmail() {
    auth.requestResetPassword(email);
  }
  return (
    <CustomLayout contentClassName="forgot-password flex-wrapper">
      <VibeTitle title="Reset password" subtitle={subtitle(email)} />
      <p className="resend-text">
        Didn&apos;t receive an email?&nbsp;
        <span className="link-text title" onClick={handleResendEmail}>
          Resend.
        </span>
      </p>
      <Link className="button-primary-360" to={routes.login}>
        Back to sign-in
      </Link>
    </CustomLayout>
  );
};

export default ResendResetPassword;
