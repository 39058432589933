import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./utils/Bugsnag";

import App from "./App";
import Log from "./utils/Log";
import { allowIframe, allowDomain, getQueryValueByKeys } from "@/utils";
import constants from "@/constants";
import "./styles/globals.css";
import "./styles/common.css";
import "./assets/fonts/font.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const element = () => {
  if (!allowIframe()) {
    return (
      <React.StrictMode>
        <div>not-allowed-iframe</div>
      </React.StrictMode>
    );
  }
  const redirectUrl = getQueryValueByKeys(constants.REDIRECT_TO);
  if (redirectUrl && !allowDomain(redirectUrl)) {
    return (
      <React.StrictMode>
        <div>not-allowed-redirect-domain</div>
      </React.StrictMode>
    );
  }
  return (
    // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    // </React.StrictMode>
  );
};
root.render(element());

Log.info("Build SHA:", process.env.REACT_APP_GIT_SHA || "unknown");
