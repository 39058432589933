import React from "react";
import {
  INPUT_INVALID_STATUS,
  InvalidHint,
  TInputInValidStatus,
  VALIDATION_RULES,
} from "../CustomInput";

export const emailInvalidHint = (invalidStatus: TInputInValidStatus) => {
  switch (invalidStatus) {
    case INPUT_INVALID_STATUS.EMPTY:
      return <InvalidHint content="Field has an invalid pattern" />;
    case INPUT_INVALID_STATUS.INVALID:
      return <InvalidHint content="Incorrect email format" />;
    default:
      return null;
  }
};

export const usernameInvalidHint = (invalidStatus: TInputInValidStatus) => {
  switch (invalidStatus) {
    case INPUT_INVALID_STATUS.EMPTY:
      return <InvalidHint content="Field has an invalid pattern" />;
    case INPUT_INVALID_STATUS.INVALID:
      return <InvalidHint content="Incorrect username format" />;
    case INPUT_INVALID_STATUS.EXCEEDED:
      return <InvalidHint content="Character limit (70) reached" />;
    default:
      return null;
  }
};

export const passwordInvalidHint = (password: string) => {
  if (!password) {
    return <InvalidHint content="Field has an invalid pattern" />;
  }
  if (!VALIDATION_RULES.PASSWORD_MIN_PATTERN.test(password)) {
    return <InvalidHint content="Use 8 characters or more for your password" />;
  }
  if (!VALIDATION_RULES.PASSWORD_MAX_PATTERN.test(password)) {
    return <InvalidHint content="Character limit (128) reached" />;
  }
  if (!VALIDATION_RULES.PASSWORD_REGEX_PATTERN.test(password)) {
    return (
      <InvalidHint content="Use at least 8 characters with 1 lowercase character, 1 uppercase character, and 1 number" />
    );
  }
  return null;
};

export const passwordCheckInvalidHint = (passwordCheck: string, password: string = "") => {
  if (!passwordCheck) {
    return <InvalidHint content="Field has an invalid pattern" />;
  }
  if (passwordCheck !== password) {
    return <InvalidHint content="Passwords don't match" />;
  }
  return null;
};

export const workspaceNameInvalidHint = (workspaceName: string) => {
  if (!workspaceName) {
    return <InvalidHint content="Field has an invalid pattern" />;
  }
  if (!VALIDATION_RULES.WORKSPACE_NAME_MAX_PATTERN.test(workspaceName)) {
    return <InvalidHint content="Character limit (100) reached" />;
  }
  return null;
};

export const codeInvalidHint = (invalidStatus: TInputInValidStatus) => {
  switch (invalidStatus) {
    case INPUT_INVALID_STATUS.INVALID:
      return (
        <>
          <InvalidHint content="The code you entered is incorrect." className="margin-bottom-0" />
          <InvalidHint content="Please try again or click Resend code." />
        </>
      );
    default:
      return null;
  }
};
