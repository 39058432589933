import React, { useEffect } from "react";
import CustomLayout from "@/components/CustomLayout";
import VibeTitle from "@/components/VibeTitle";
import Link from "@/components/CustomLink";
import { routes } from "@/constants";
import { useAuth, useRedirectNavigation } from "@/hooks";

const NoWorkspaceLoading = () => {
  const auth = useAuth();
  const navigate = useRedirectNavigation();

  useEffect(() => {
    if (!auth?.authState?.email) {
      navigate(routes.corsSync);
    }
  }, []);

  const subtitle = `You signed in as ${auth?.authState?.email}. We recommend creating a workspace to do some creative work on your own or to collaborate with others.`;
  return (
    <CustomLayout contentClassName="flex-wrapper">
      <VibeTitle title="You don't have any workspaces yet" subtitle={subtitle} />
      <Link to={routes.createWorkspace} className="button-primary-360">
        Create workspace
      </Link>
    </CustomLayout>
  );
};

export default NoWorkspaceLoading;
