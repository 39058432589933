import React, { useRef, useState, useEffect } from "react";

import CustomInput, {
  INPUT_INVALID_STATUS,
  TInputInValidStatus,
  VALIDATION_RULES,
} from "../CustomInput";
import { usernameInvalidHint } from "./invalidInputHints";

const SubmitUsername = ({ onSubmit }: { onSubmit: (username: string) => void }) => {
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const [username, setUsername] = useState<string>("");
  const [invalidStatus, setInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );

  function testInputValidation(username: string) {
    if (!username) {
      setInvalidStatus(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    if (!VALIDATION_RULES.USERNAME_REGEX_PATTERN.test(username)) {
      setInvalidStatus(INPUT_INVALID_STATUS.EXCEEDED);
      return false;
    }
    setInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function handleUsernameInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const username = e?.target?.value || "";
    setUsername(username);
    if (invalidStatus !== INPUT_INVALID_STATUS.INITIAL) {
      testInputValidation(username.trim());
    }
  }

  function handleUsernameFinished() {
    if (testInputValidation(username.trim())) {
      const trimUsername = username.trim();
      setUsername(trimUsername);
      onSubmit(trimUsername);
    }
  }

  useEffect(() => {
    usernameInputRef?.current?.focus();
  }, []);

  return (
    <>
      <CustomInput
        inputRef={usernameInputRef}
        type="username"
        value={username}
        onChange={handleUsernameInputChange}
        onEnter={handleUsernameFinished}
        invalidHintContent={usernameInvalidHint(invalidStatus)}
      />
      <button className="button-primary-360" onClick={handleUsernameFinished}>
        Continue
      </button>
    </>
  );
};

export default SubmitUsername;
