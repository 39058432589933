import React, { useMemo } from "react";

import VibeTitle from "@/components/VibeTitle";
import RegisterUser from "@/components/CustomForm/RegisterUser";
import CustomLayout from "@/components/CustomLayout";
import Link from "@/components/CustomLink";
import SSOLoginBtns from "@/components/SSOLoginBtns";

import { useAuth } from "@/hooks/authHooks";
import constants, { routes } from "@/constants";

const Register = () => {
  const auth = useAuth();
  const prefilledEmail =
    auth?.authState?.email || sessionStorage.getItem(constants.EMAIL_KEY) || "";

  const policyLinks = useMemo(
    () => (
      <p className="policy-para">
        By creating an account, you agree to the&nbsp;
        <a className="policy-para-link" href="https://vibe.us/terms-of-service/">
          Vibe Terms of service
        </a>
        &nbsp;and&nbsp;
        <a className="policy-para-link" href="https://vibe.us/privacy/">
          Privacy policy
        </a>
        .
      </p>
    ),
    []
  );

  const loginLink = useMemo(
    () => (
      <div className="bottom-link-wrapper">
        <span className="title">Already have a Vibe account?</span>
        <Link className="title link" to={routes.login}>
          Sign In
        </Link>
      </div>
    ),
    []
  );

  return (
    <CustomLayout contentClassName="register-content" footer={SSOLoginBtns(policyLinks)}>
      <VibeTitle title="Create a Vibe account" />
      <RegisterUser prefilledEmail={prefilledEmail} />
      {loginLink}
    </CustomLayout>
  );
};

export default Register;
