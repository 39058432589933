import React from "react";

const DisabledCheckedBox = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z"
        fill="#CCCCCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9109 8.93128L11.5715 15.9046C11.377 16.1186 11.0992 16.2379 10.81 16.2317C10.5209 16.2254 10.2485 16.0943 10.0634 15.8721L6.58984 11.7038L8.12629 10.4235L10.8644 13.7092L16.431 7.58594L17.9109 8.93128Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
};
export default DisabledCheckedBox;
