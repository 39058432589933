import React, { useRef, useState, useEffect } from "react";

import CustomInput from "../CustomInput";
import { workspaceNameInvalidHint } from "./invalidInputHints";
import LoadingButton from "../LoadingButton";

const SubmitWorkspaceName = ({
  prefilledValue,
  isLoading,
  onSubmit,
}: {
  prefilledValue?: string;
  isLoading: boolean;
  onSubmit: (workspaceName: string) => void;
}) => {
  const workspaceNameInputRef = useRef<HTMLInputElement>(null);
  const [workspaceName, setWorkspaceName] = useState<string>(prefilledValue ?? "");
  const [invalidHints, setInvalidHints] = useState<React.ReactNode>(null);

  function handleWorkspaceNameInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const workspaceName = e?.target?.value || "";
    setWorkspaceName(workspaceName);
  }

  function handleWorkspaceNameFinished() {
    const trimWorkspaceName = workspaceName.trim();
    const invalidCheckRes = workspaceNameInvalidHint(trimWorkspaceName);
    setWorkspaceName(trimWorkspaceName);
    setInvalidHints(invalidCheckRes);
    if (!invalidCheckRes) {
      setWorkspaceName(trimWorkspaceName);
      onSubmit(trimWorkspaceName);
    }
  }

  useEffect(() => {
    workspaceNameInputRef?.current?.focus();
  }, []);

  return (
    <>
      <CustomInput
        inputRef={workspaceNameInputRef}
        placeholder={prefilledValue}
        type="workspaceName"
        value={workspaceName}
        onChange={handleWorkspaceNameInputChange}
        onEnter={handleWorkspaceNameFinished}
        invalidHintContent={invalidHints}
      />
      <LoadingButton
        className="button-primary-360"
        isLoading={isLoading}
        onClick={handleWorkspaceNameFinished}
      >
        Create
      </LoadingButton>
    </>
  );
};

export default SubmitWorkspaceName;
