import React, { useEffect, useState } from "react";

import VibeTitle from "@/components/VibeTitle";
import Link from "@/components/CustomLink";
import { DoubleCheckPassword } from "@/components/CustomForm";
import UnknownError from "@/components/UnknownError";

import constants, { routes } from "@/constants";
import { getQueryValueByKeys } from "@/utils";
import Log from "@/utils/Log";
import { useAuth, useRedirectNavigation } from "@/hooks";
import CustomLayout from "@/components/CustomLayout";
import LoadingMask from "@/components/LoadingMask";

const SentPasswordSuccess = () => (
  <CustomLayout contentClassName="flex-wrapper">
    <VibeTitle title="Password saved successfully." />
    <Link className="button-primary-360" to={routes.login}>
      Back to sign-in
    </Link>
  </CustomLayout>
);

const subtitle = (email: string) => (
  <>
    Enter a new password for <em className="em-email">{email}</em>
  </>
);

const SetNewPassword = ({
  email,
  onSentSuccess,
}: {
  email: string;
  onSentSuccess: VoidFunction;
}) => {
  const [resetFailed, setResetFailed] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const auth = useAuth();
  const navigate = useRedirectNavigation();
  const requestId = getQueryValueByKeys(constants.REQUEST_ID);

  function handlePasswordChecked(newPassword: string) {
    setLoading(true);
    auth.resetPassword(email, requestId, newPassword).then((resentSuccess) => {
      setLoading(false);
      if (resentSuccess) {
        onSentSuccess();
      } else {
        setResetFailed(true);
      }
    });
  }

  useEffect(() => {
    if (!requestId) {
      Log.error("reset password error: request_id missing");
      navigate(routes.unknownError);
    }
  });

  return resetFailed ? (
    <UnknownError />
  ) : (
    <CustomLayout>
      <LoadingMask isLoading={isLoading} />
      <VibeTitle title="Reset password" subtitle={subtitle(email)} />
      <DoubleCheckPassword
        isLoading={isLoading}
        email={email}
        submitBtnText="Reset password"
        onPasswordChecked={handlePasswordChecked}
      />
    </CustomLayout>
  );
};

const ResetPassword = () => {
  const [sentSuccess, setSentSuccess] = useState<boolean>(false);

  const email = getQueryValueByKeys(constants.EMAIL_KEY);

  function handleSentSuccess() {
    setSentSuccess(true);
  }

  return sentSuccess ? (
    <SentPasswordSuccess />
  ) : (
    <SetNewPassword email={email} onSentSuccess={handleSentSuccess} />
  );
};

export default ResetPassword;

// http://localhost:3001/
// http://localhost:3001/reset-password?email=bella%40vibe.us&ip=192.168.0.1&platform=Mac+OS+X+10&request_id=7dWkucY60D_Ki3j2kDy3KUzHJUF7dw&t=1704188782
