import React, { useEffect, useRef, useState } from "react";
import CustomImg from "@/components/CustomImg";
import { IJoinItem } from "../../interface";
import Divider from "./components/Divider";
import WorkspaceAvatar from "./components/WorkspaceAvatar";
import { threshold, roleMapping } from "./constants";
import "./styles.css";
import CheckedBox from "./components/CheckedBox";
import CheckBox from "./components/CheckBox";
import DisabledCheckedBox from "./components/DisabledCheckedBox";

const JoinItem = ({
  selected,
  iconUrl,
  displayName,
  role,
  memberNum = 1,
  className,
  disabledChecked,
  onSelectionClicked,
}: IJoinItem) => {
  const nameRef = useRef<HTMLDivElement>(null);
  const [animationClass, setAnimationClass] = useState<string>("");

  useEffect(() => {
    if (nameRef?.current && nameRef.current.offsetWidth > threshold) {
      setAnimationClass("overflow-ellipsis");
    }
  }, [nameRef]);

  return (
    <div className={`selection-item ${className}`} onClick={onSelectionClicked}>
      <CustomImg className="avatar" src={iconUrl} alt="avatar" defaultImg={WorkspaceAvatar} />
      <div className={`info-wrapper ${animationClass}`}>
        <div ref={nameRef} className="name">
          {displayName}
        </div>
        {role && (
          <div className="team-info">
            <div className="role">{roleMapping[role]}</div>
            <Divider />
            <div className="team-total">{`${memberNum} ${
              memberNum > 1 ? "members" : "member"
            }`}</div>
          </div>
        )}
      </div>
      {selected ? disabledChecked ? <DisabledCheckedBox /> : <CheckedBox /> : <CheckBox />}
    </div>
  );
};

export default JoinItem;
