import React from "react";

const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7224 12.959C17.0925 12.5723 17.0925 11.9627 16.7224 11.576L10.0309 4.58575L8.58612 5.96876L14.6157 12.2675L8.58612 18.5662L10.0309 19.9492L16.7224 12.959Z"
      fill="#222222"
    />
  </svg>
);

export default ArrowIcon;
