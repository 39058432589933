import React, { useEffect, useMemo, useState } from "react";

import CustomLayout from "@/components/CustomLayout";
import VibeTitle from "@/components/VibeTitle";
import message from "@/components/CustomMessage";
import { useAuth } from "@/hooks";
import type { IJoinTeamInfo } from "@/hooks";
import { getQueryValueByKeys } from "@/utils";
import Log from "@/utils/Log";
import RegisterUser from "@/components/CustomForm/RegisterUser";
import LoadingButton from "@/components/LoadingButton";
import LoadingMask from "@/components/LoadingMask";
import CustomInput from "@/components/CustomInput";

const RegisterByInvitation = () => {
  const auth = useAuth();
  const email = getQueryValueByKeys("email");
  const code = getQueryValueByKeys("code");
  const teamId = getQueryValueByKeys("team_id");
  const teamName = getQueryValueByKeys("team_name");
  const [loading, setLoading] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);

  const registerTitle = useMemo(
    () => (
      <>
        Create a Vibe account to join <br />
        &quot;{teamName}&quot;
      </>
    ),
    [teamName]
  );

  const loginTitle = useMemo(() => `Sign in to join "${teamName}"`, [teamName]);

  const handleLoginAndJoinTeam = () => {
    setLoading(true);
    auth.trySignInWithSSO(email).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!email || !code || !teamId) {
      Log.error("register and join team failed: missing join code");
      message.error("Join team workspace failed, please retry!");
      return;
    }
    const joinTeamInfo: IJoinTeamInfo = { email, code, teamId, teamName };
    auth.checkEmailRegistered(email).then((isRegistered) => {
      setIsRegistered(isRegistered);
      auth.tryJoinTeamInfo(joinTeamInfo, isRegistered);
    });
  }, []);

  return (
    <CustomLayout>
      {isRegistered ? (
        <>
          <LoadingMask isLoading={loading} />
          <VibeTitle title={loginTitle} />
          <CustomInput type="email" value={email} disabled />
          <LoadingButton
            className="button-primary-360"
            isLoading={loading}
            onClick={handleLoginAndJoinTeam}
          >
            Continue
          </LoadingButton>
        </>
      ) : (
        <>
          <VibeTitle title={registerTitle} />
          <RegisterUser fixEmail={email} />
        </>
      )}
    </CustomLayout>
  );
};

export default RegisterByInvitation;

// http://localhost:3001/register-by-invitation?code=K77RCXVV7A3G5SKDKPX7TYBOXH7A6RYY&email=bella%40vibe.us&register=false&team_id=team.9bJMkhVBTGas0lQPeACTAY&team_name=AXin%27s+workspace

// http://localhost:3001/register-by-invitation?code=3RQPG34YVVTGODXI4XCYI24CFQRYTVF7&email=pokeren785%40gianes.com&register=true&team_id=team.c3yLs2NEGiQkLJ1N5Gl64R&team_name=A+our%27s+workspace
