// TODO: deprecated! Will be replace by OTP code and password login

import React, { useState } from "react";
import message from "@/components/CustomMessage";

import CustomLayout from "@/components/CustomLayout";
import VibeTitle from "@/components/VibeTitle";
import ApprovalInfoList from "@/components/ApprovalInfoList";
import UnknownError from "@/components/UnknownError";
import { useAuth } from "@/hooks";
import { getQueryValueByKeys } from "@/utils";
import Log from "@/utils/Log";

import constants from "@/constants";

const LoginState = {
  APPROVED: "approved",
  UNAPPROVED: "unapproved",
  EXPIRED: "expired",
};

const UnApprovalContent = ({ onApproval }: { onApproval: AuthVoidFun }) => {
  const auth = useAuth();

  const handleLoginApprovalClick = () => {
    const requestId = getQueryValueByKeys(constants.REQUEST_ID);
    const resolveKey = getQueryValueByKeys(constants.RESOLVE_KEY);
    if (requestId && resolveKey) {
      auth.emailBoardApproval(requestId, resolveKey).then((hasApproved) => {
        onApproval(hasApproved);
      });
    } else {
      const errorMsg = "Sign-in request failed";
      message.error(errorMsg);
      Log.error(errorMsg);
    }
  };

  return (
    <CustomLayout>
      <VibeTitle
        title="Do you want to approve this request?"
        subtitle="We received a sign-in request to Vibe. If you initiated this request, please click the Approve button to sign in."
      />
      <ApprovalInfoList />
      <button className="button-primary-360" onClick={handleLoginApprovalClick}>
        Approve
      </button>
    </CustomLayout>
  );
};

const WebLoginApproval = () => {
  const [currentStatus, setCurrentStatus] = useState(LoginState.UNAPPROVED);

  const handleApproval = (hasApproved: boolean) => {
    if (hasApproved) {
      setCurrentStatus(LoginState.APPROVED);
    } else {
      setCurrentStatus(LoginState.EXPIRED);
    }
  };

  switch (currentStatus) {
    case LoginState.APPROVED:
      return (
        <CustomLayout>
          <VibeTitle
            title="You have successfully approved login request."
            subtitle="You can now access your Vibe account."
          />
        </CustomLayout>
      );
    case LoginState.EXPIRED:
      return (
        <UnknownError subtitle="This link has expired. Please submit a new request to receive a new link via email." />
      );
    default:
      return <UnApprovalContent onApproval={handleApproval} />;
  }
};

export default WebLoginApproval;

// http://localhost:3001/tv-approval?request_id=Rozoy2bDOf9-k9QexFFWq2KgiAI34_Yp&email=bella%40astarx.cn&ip=192.168.0.1&platform=Mac+OS+X+10&resolve_key=rDVn0LVMk4bHr8WE14ztDjCPA9irnUKj&t=1701225359
