import React, { useEffect, useState } from "react";
import { useAuth } from "@/hooks";
import VibeTitle from "@/components/VibeTitle";
import constants from "@/constants";
import { addRedirectParam, compareTeamByRoleAndName } from "@/utils";
import { SelectionItem } from "./components/SelectionItem";
import { getRedirectDomainUrl } from "@/api";
import CustomLayout from "@/components/CustomLayout";
import { ISelectionItem } from "./interface";

const SelectAdminWorkspace = () => {
  const admin = "admin" as SignInApps;
  const [teamList, setTeamList] = useState<ISelectionItem[]>([]);

  const auth = useAuth();

  useEffect(() => {
    auth.getAdminWorkspaceSelection().then((teamInfoListRes) => {
      const accessToken = localStorage.getItem(constants.ACCESS_TOKEN) || "";
      const currentTeamList = teamInfoListRes
        .map((team) => ({
          ...team,
          href: addRedirectParam(getRedirectDomainUrl(admin), team.teamId, accessToken),
        }))
        .sort(compareTeamByRoleAndName);
      setTeamList(currentTeamList);
    });
  }, []);

  return (
    <CustomLayout contentClassName="join-select-workspace">
      {teamList?.length ? (
        <>
          <VibeTitle
            title="Select a workspace"
            subtitle="Choose the workspace that you want to use when signing in to Vibe One Admin. You can switch to
    other workspace anytime."
          />
          <div className="selection-scroll-container">
            <div className="selection-wrapper">
              {teamList.map((team, index, arr) => (
                <SelectionItem
                  key={team.teamId}
                  className={index === arr.length - 1 ? "last-item" : ""}
                  {...team}
                />
              ))}
            </div>
          </div>
        </>
      ) : null}
    </CustomLayout>
  );
};

export default SelectAdminWorkspace;
