import React, { useCallback, useState } from "react";

import { ITeamInfoObj } from "@/api/interface";
import { JoinItem } from "../SelectionItem";
import VibeTitle from "@/components/VibeTitle";
import Link from "@/components/CustomLink";
import { routes } from "@/constants";
import { useAuth } from "@/hooks";
import LoadingButton from "@/components/LoadingButton";
import LoadingMask from "@/components/LoadingMask";

const JoinWorkspaceSelections = ({ teamInvitations }: { teamInvitations: ITeamInfoObj[] }) => {
  const auth = useAuth();
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [isLoading, setLoading] = useState(false);

  const handleJoinSelectionClicked = (teamId: string) => {
    setSelectedItems((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(teamId)) {
        newSelected.delete(teamId);
      } else {
        newSelected.add(teamId);
      }
      return newSelected;
    });
  };

  const handleJoinSubmitBtnClicked = useCallback(() => {
    setLoading(true);
    auth.joinWorkspacesByTeamIds([...selectedItems]).finally(() => {
      setLoading(false);
    });
  }, [selectedItems]);

  return teamInvitations.length ? (
    <>
      <LoadingMask isLoading={isLoading} />
      <VibeTitle
        title="Join a workspace"
        subtitle={`You have been invited to the following workspace${teamInvitations.length > 1 ? "s" : ""}.`}
      />
      <div className="selection-wrapper">
        {teamInvitations.map((team, index, arr) => (
          <JoinItem
            key={team.teamId}
            selected={selectedItems.has(team.teamId)}
            disabledChecked={isLoading}
            className={index === arr.length - 1 ? "last-item" : ""}
            onSelectionClicked={() => handleJoinSelectionClicked(team.teamId)}
            {...team}
          />
        ))}
      </div>
      <LoadingButton
        className={`button-primary-360 join-submit-btn${!selectedItems.size ? " disabled" : ""}`}
        isLoading={isLoading}
        onClick={handleJoinSubmitBtnClicked}
        disabled={!selectedItems.size}
      >
        Join
      </LoadingButton>
      <p className="create-hint">You can also create your own workspace</p>
      <Link className="create-workspace-link" to={routes.createWorkspace}>
        {"Create a workspace"}
      </Link>
      <div className="placeholder" />
    </>
  ) : null;
};

export default JoinWorkspaceSelections;
