import React, { useEffect, useRef, useState } from "react";
import CustomImg from "@/components/CustomImg";
import { ISelectionItem } from "../../interface";
import Log from "@/utils/Log";
import constants from "@/constants";
import Divider from "./components/Divider";
import ArrayIcon from "./components/ArrowIcon";
import WorkspaceAvatar from "./components/WorkspaceAvatar";
import { threshold, roleMapping } from "./constants";
import "./styles.css";

const SelectionItem = ({
  teamId,
  href,
  iconUrl,
  displayName,
  role,
  memberNum = 1,
  className,
}: ISelectionItem) => {
  const nameRef = useRef<HTMLDivElement>(null);
  const [animationClass, setAnimationClass] = useState<string>("");

  useEffect(() => {
    if (nameRef?.current && nameRef.current.offsetWidth > threshold) {
      setAnimationClass("overflow-ellipsis");
    }
  }, [nameRef]);

  function onSelectionClicked() {
    Log.debug("save workspace selection", teamId);
    localStorage.setItem(constants.TEAM_ID, teamId);
  }

  return (
    <a className={`selection-item ${className}`} href={href} onClick={onSelectionClicked}>
      <CustomImg className="avatar" src={iconUrl} alt="avatar" defaultImg={WorkspaceAvatar} />
      <div className={`info-wrapper ${animationClass}`}>
        <div ref={nameRef} className="name">
          {displayName}
        </div>
        {role && (
          <div className="team-info">
            <div className="role">{roleMapping[role]}</div>
            <Divider />
            <div className="team-total">{`${memberNum} ${
              memberNum > 1 ? "members" : "member"
            }`}</div>
          </div>
        )}
      </div>
      <ArrayIcon />
    </a>
  );
};

export default SelectionItem;
